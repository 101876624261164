<template>
<v-navigation-drawer
    style="width:200px"
    touchless
    mobile-breakpoint="600"
    fixed
    app
    floating
    :expand-on-hover="mini"
    :value="drawer"
    :right="$vuetify.rtl"
    class="my-4 ms-3 border-radius-lg"
    :class="!$vuetify.breakpoint.xs ? '' : 'bg-white'"
    :data-color="sidebarColor"
    :data-theme="sidebarTheme">
    <div class="v-navigation-drawer-brand d-flex justify-center align-center mt-2">
        <v-img
            :src="require('@/assets/logo1.png')"
            height="120"
            contain
            class="mt-2" />
    </div>
    <div class="v-navigation-drawer-brand d-flex justify-center align-center">
        <h6 class="text-h6 font-weight-bolder text-primary mt-2">
            관리자
        </h6>
    </div>

    <hr class="horizontal dark mt-6 mb-4" />

    <v-list nav dense>
        <div v-for="item in items" :key="item.title">
            <h5 v-if="item.title!='홈'" class="
          text-uppercase text-caption
          ls-0
          font-weight-bolder
          p-0
          text-muted
          mx-4
          mt-4
          mb-2
          ps-2
          d-none-mini
          white-space-nowrap
        ">
                {{ item.title }}
            </h5>

            <div class="pb-1 mx-0">
                <div v-for="child in item.items" :key="child.title">
                    <v-list-group
                        v-if="child.items"
                        :ripple="false"
                        :key="child.title"
                        v-model="child.active"
                        append-icon="fas fa-angle-down"
                        active-class="item-active">
                        <template v-slot:activator>
                            <v-list-item-icon class="shadow border-radius-md me-2 align-center justify-center pa-0">
                                <div class="text-sm">
                                    <i :class="child.icon" :style="child.active?'color:white':'color:#757575'" />
                                </div>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="child.title" class="ms-1"></v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <v-list-item
                            :ripple="false"
                            link
                            class="mb-0 no-default-hover"
                            :class="child.active ? 'item-active' : ''"
                            v-for="child2 in child.items"
                            :key="child2.title"
                            :to="child2.link">
                            <span class="v-list-item-mini"></span>
                            <v-list-item-content class="ms-4 ps-4" v-if="!child2.items">
                                <v-list-item-title v-text="child2.title" @click="onClickChild2($event, child, child2)"></v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-content class="ms-4 ps-4 py-0" v-if="child2.items">
                                <v-list-group
                                    prepend-icon=""
                                    :ripple="false"
                                    sub-group
                                    no-action
                                    v-model="child2.active">
                                    <template v-slot:activator>
                                        <v-list
                                            nav
                                            dense
                                            class="pa-0">
                                            <v-list-group
                                                :ripple="false"
                                                append-icon="fas fa-angle-down me-auto ms-1"
                                                active-class="item-active"
                                                class="mb-0">
                                                <template v-slot:activator class="mb-0">
                                                    <v-list-item-content class="py-0">
                                                        <v-list-item-title v-text="child2.title"></v-list-item-title>
                                                    </v-list-item-content>
                                                </template>
                                            </v-list-group>
                                        </v-list>
                                    </template>

                                    <v-list-item
                                        v-for="child3 in child2.items"
                                        :ripple="false"
                                        :key="child3.title"
                                        :to="child3.link"
                                        @click="onClickChild3($event, child, child2, child3)">
                                        <v-list-item-content>
                                            <span class="v-list-item-mini"></span>
                                            <v-list-item-title v-text="child3.title"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-group>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>

                    <v-list-item
                        v-else
                        :ripple="false"
                        :key="child.title"
                        :to="child.link"
                        @click="deactivateAll();child.active=true">
                        <v-list-item-icon class="shadow border-radius-md me-2 align-center justify-center pa-0">
                            <div class="text-sm">
                                <i :class="child.icon" :style="getChildIconColor(child)" />
                            </div>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title v-text="child.title" class="ms-1"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </div>
            </div>
          <v-btn
            depressed
            :ripple="false"
            class="font-weight-600 text-body ls-0 text-capitalize mx-0"
            color="transparent"
            @click="logout">
            <v-icon size="16">fa fa-sign-out-alt me-sm-2 text-sm</v-icon>
            <span class="d-sm-inline font-weight-bold d-none" :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }" :style="!hasBg ? 'color: rgba(0,0,0, .6)' : ''">로그아웃</span>
          </v-btn>
        </div>
    </v-list>
    <div style="height:50px"></div>
</v-navigation-drawer>
</template>

<script>
export default {
    name: "drawer",
    props: {
        drawer: {
            type: Boolean,
            default: null,
        },
        sidebarColor: {
            type: String,
            default: "success",
        },
        sidebarTheme: {
            type: String,
            default: "transparent",
        },
        items: {
            type: Array,
            default: null,
        },
    },
    data: () => ({
        mini: false,
        togglerActive: false,
        thirdLevelSimple: [
            "Third level menu",
            "Just another link",
            "One last link",
        ],
    }),
    methods: {
        onClickChild2(event, child1, child2) {
            if ((event != null) && (event != undefined)) {
                this.listClose(event);
            }
            this.deactivateAll();
            child1.active = true;
            child2.active = true;
        },
        onClickChild3(event, child1, child2, child3) {
            if ((event != null) && (event != undefined)) {
                this.listClose(event);
            }
            this.deactivateAll();
            child1.active = true;
            child2.active = true;
            child3.active = true;
        },
        listClose(event) {
            let items;
            let headers;
            let groups;
            let currentEl;

            if (
                document.querySelectorAll(
                    ".mb-0.v-list-item.v-list-item--link.item-active"
                )
            ) {
                items = document.querySelectorAll(
                    ".mb-0.v-list-item.v-list-item--link.item-active"
                );
            }

            if (
                document.querySelectorAll(
                    ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
                )
            ) {
                headers = document.querySelectorAll(
                    ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
                );
            }

            if (
                document.querySelectorAll(
                    ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
                )
            ) {
                groups = document.querySelectorAll(
                    ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
                );
            }

            if (
                event.target.closest(
                    ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
                )
            ) {
                currentEl = event.target.closest(
                    ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
                );
            }

            if (items != null) {
                for (var i = 0; i < items.length; i++) {
                    items[i].classList.remove("item-active");
                }
            }

            if (headers != null) {
                for (var j = 0; j < headers.length; j++) {
                    headers[j].classList.remove(
                        "v-list-item--active",
                        "item-active",
                        "primary--text"
                    );
                    headers[j].setAttribute("aria-expanded", false);
                }
            }

            if (groups != null) {
                for (var k = 0; k < groups.length; k++) {
                    groups[k].classList.remove("v-list-group--active", "primary--text");
                }
            }

            if (event.target.closest(".mb-0.v-list-item.v-list-item--link")) {
                event.target
                    .closest(".mb-0.v-list-item.v-list-item--link")
                    .classList.add("item-active");
            }

            if (currentEl != null) {
                currentEl
                    .querySelector(".v-list-group__header")
                    .classList.add("v-list-item--active", "item-active");
            }
        },
        deactivateAll() {
            for (var i = 0; i < this.items.length; i++) {
                var item = this.items[i];
                item.active = false;
                if (item.items) {
                    for (var j = 0; j < item.items.length; j++) {
                        var child = item.items[j];
                        child.active = false;
                    }
                }
            }
        },
        getChildIconColor(child) {
            var color = 'color:#757575';
            if (this.$route.path == child.link) {
                color = 'color:white';
            } else if (child.title == '조제현황') {
                if (this.$route.name == '접수정보') {
                    color = 'color:white';
                }
            }
            return color;
        },
        async logout() {
          await this.$auth.logout();
          this.$router.replace("/pages/auth/signin");
        },
    },
    mounted() {},
};
</script>
