<template>
<v-app-bar
    :color="background"
    height="auto"
    class="mt-5 px-0 mx-6 border-radius-xl toolbar-content-padding-y-none"
    :class="
      navbarFixed
        ? 'position-sticky blur shadow-blur top-1 z-index-sticky py-2'
        : ''
    "
    flat>
    <v-row class="py-1">
        <v-col
            cols="12"
            sm="6"
            class="d-flex align-center">
            <div>
                <v-breadcrumbs class="pb-0 px-0">
                    <v-breadcrumbs-item
                        to="/pages/dashboard/dashboard"
                        active-class="active-breadcrumb"
                        class="opacity-5 text-dark">
                        <v-icon style="margin-bottom:2px">fas fa-home</v-icon>
                    </v-breadcrumbs-item>
                    <li v-if="$route.name!='홈화면'" class="v-breadcrumbs__divider opacity-5 px-2 text-muted">/</li>
                    <v-breadcrumbs-item class="opacity-5 text-dark">
                        {{ $route.meta.groupName }}
                    </v-breadcrumbs-item>
                    <li v-if="$route.name!='홈화면'" class="v-breadcrumbs__divider opacity-5 px-2 text-muted">/</li>
                    <v-breadcrumbs-item v-if="$route.meta.secondGroupName!=null" class="opacity-5 text-dark">
                        {{ $route.meta.secondGroupName }}
                    </v-breadcrumbs-item>
                    <li v-if="$route.meta.secondGroupName!=null" class="v-breadcrumbs__divider opacity-5 px-2 text-muted">/</li>
                    <v-breadcrumbs-item v-if="$route.meta.thirdGroupName!=null" class="opacity-5 text-dark">
                        {{ $route.meta.thirdGroupName }}
                    </v-breadcrumbs-item>
                    <li v-if="$route.meta.thirdGroupName!=null" class="v-breadcrumbs__divider opacity-5 px-2 text-muted">/</li>
                </v-breadcrumbs>

                <h6 class="text-h6 font-weight-bolder text-typo mb-0">
                    {{ $route.meta.name }}
                </h6>
            </div>
            <!--div
                class="drawer-toggler pa-5 ms-6 cursor-pointer"
                :class="{ active: togglerActive }"
                @click="minifyDrawer"
                v-if="!$vuetify.breakpoint.xs">
                <div class="drawer-toggler-inner">
                    <i class="drawer-toggler-line bg-body"></i>
                    <i class="drawer-toggler-line bg-body"></i>
                    <i class="drawer-toggler-line bg-body"></i>
                </div>
            </div-->
        </v-col>

        <v-col
            cols="12"
            sm="6"
            class="d-flex align-center justify-end"
            :class="$vuetify.rtl ? 'text-sm-left' : 'text-sm-right'">
            <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                class="
                    font-weight-600
                    text-capitalize
                    drawer-toggler
                    py-3
                    px-0
                    rounded-sm
                    mx-0
                    active
                    btn-dark-toggler-hover
                "
                v-if="$vuetify.breakpoint.xs"
                color="transparent"
                @click="toggleDrawer">
                <div class="drawer-toggler-inner">
                    <i class="drawer-toggler-line text-body"></i>
                    <i class="drawer-toggler-line text-body"></i>
                    <i class="drawer-toggler-line text-body"></i>
                </div>
            </v-btn>
<!--            <v-btn-->
<!--                depressed-->
<!--                :ripple="false"-->
<!--                class="font-weight-600 text-body ls-0 text-capitalize mx-0"-->
<!--                color="transparent"-->
<!--                @click="logout">-->
<!--                <v-icon size="16">fa fa-sign-out-alt me-sm-2 text-sm</v-icon>-->
<!--                <span class="d-sm-inline font-weight-bold d-none" :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }" :style="!hasBg ? 'color: rgba(0,0,0, .6)' : ''">로그아웃</span>-->
<!--            </v-btn>-->
        </v-col>
    </v-row>
</v-app-bar>
</template>

<script>
export default {
    name: "app-bar",
    props: {
        background: String,
        hasBg: Boolean,
        linkColor: String,
        toggleActive: Boolean,
        navbarFixed: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            drawer: false,
            togglerActive: false,
            dropdown: [{
                    avatar: require("@/assets/img/team-2.jpg"),
                    title: '<span class="font-weight-bold">New message</span> from Laur</h6>',
                    time: "13 minutes ago",
                },
                {
                    image: require("@/assets/img/small-logos/logo-spotify.svg"),
                    title: '<span class="font-weight-bold">New album</span> by Travis Scott',
                    time: "1 day",
                },
                {
                    icon: require("@/assets/img/icons/card.svg"),
                    title: "Payment successfully completed",
                    time: "2 days",
                },
            ],
        };
    },
    methods: {
        toggleDrawer() {
            this.togglerActive = !this.togglerActive;
            this.$emit("drawer-toggle", true);
        },
        minifyDrawer() {
            this.togglerActive = !this.togglerActive;
            this.mini = !this.mini;
            const body = document.getElementsByTagName("body")[0];

            if (body.classList.contains("drawer-mini")) {
                body.classList.remove("drawer-mini");
            } else {
                body.classList.add("drawer-mini");
            }
        },
        async logout() {
            await this.$auth.logout();
            this.$router.replace("/pages/auth/signin");
        },
    },
    watch: {
        toggleActive(val) {
            this.togglerActive = val;
        },
    },
};
</script>
